import React from 'react';
import { navigate } from 'gatsby';
import './index.scss';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import MainFooter from '../../components/main-footer/main-footer';

const block = 'page-error';
const Page = () => {
  return (
    <main className={`container--mobile ${block}`}>
      <MainHeader />
      <PageHeader
        title="Ops, você não está logado"
        description={
          <span>
            Você precisa estar logado no Portal Akad Digital para acessar esse
            site.
            <br />
          </span>
        }
        breadCrumbs={[]}
        hide
        campaignHide={true}
      />
      <div className="container">
        <Button
          theme={'secondary'}
          className="button_cta"
          onClick={() => {
            navigate(
              `${process.env.GATSBY_URL_DIGITAL}/redirecionar?url=https://empresarial.akadseguros.com.br&p=42be02b34396ddca83sA`
            );
          }}
        >
          Fazer login no Portal Akad Digital
        </Button>
      </div>
      <MainFooter />
    </main>
  );
};

export default Page;
